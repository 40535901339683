import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";

const UKVISelt = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "UKVISELT - Preston Academy of English";
  }, []);
  return (
    <>
      <Header />
      <div className="general-cont">
        <h1 className="general-header">
          UKVI SELT A1 / A2 / B1 Test Preparation
        </h1>
        <div className="general-detailCont">
          <p className="general-para">
            {t("ukvi_pbreak1")} <br /> <br /> {t("ukvi_pbreak2")}
          </p>
          <div className="general-list">
            <p className="generalList-header">
              <span>{t("ukvi_span_red")}</span> {t("ukvi_span")}
            </p>
            <p className="ukviTest-TestImpo">{t("ukvi_no_red")}</p>
          </div>
          {/* //! For the table */}
          <div className="general-tableCont">
            <h1>Course Summary</h1>
            <table className="general-table">
              <tbody>
                <tr>
                  <td>Study Hours:</td>
                  <td>2 Per Week</td>
                </tr>
                <tr>
                  <td>Timetable:</td>
                  <td>Flexible</td>
                </tr>
                <tr>
                  <td>Price:</td>
                  <td> £50</td>
                </tr>
                <tr>
                  <td>Age Requirement:</td>
                  <td>18+</td>
                </tr>
                <tr>
                  <td>Class Size Max:</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Average Class Size:</td>
                  <td>1 - 3</td>
                </tr>
              </tbody>
            </table>
          </div>

          <NavLink to="/apply-now" className="all-courseBtn">
            Book Now
          </NavLink>

          <div className="generalOther-courseCont">
            <h4 className="generalOtherCourse-header">
              {t("ukviaddmorecourse")}
            </h4>
            <div className="generalOther-boxCont">
              <NavLink to="/general-english" className="generalOther-courseBox">
                General English
              </NavLink>
              <NavLink to="/english-ielts" className="generalOther-courseBox">
                IELTS Exam Preparation
              </NavLink>
              <NavLink to="/speaking-skills" className="generalOther-courseBox">
                Speaking Skills
              </NavLink>

              <NavLink to="/onetoone" className="generalOther-courseBox">
                1 To 1 Courses
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UKVISelt;
