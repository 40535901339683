import React from "react";
import { FaCity, FaPeopleCarry } from "react-icons/fa";

import "./style/learn.css";
import { SiGoogleclassroom } from "react-icons/si";
import { GiBookCover, GiFamilyHouse } from "react-icons/gi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";

const LearnEnglish = () => {
  const { t } = useTranslation();

  const LogoData = [
    {
      logo: <FaCity className="logo-img" />,
      parah: t("GreatLoc"),
    },
    {
      logo: <SiGoogleclassroom className="logo-img" />,
      parah: t("SmallNum"),
    },
    {
      logo: <FaPeopleCarry className="logo-img" />,
      parah: t("smallClass"),
    },
    {
      logo: <GiFamilyHouse className="logo-img" />,
      parah: t("AccomWalk"),
    },
    {
      logo: <GiBookCover className="logo-img" />,
      parah: t("OnlineSuite"),
    },
    {
      logo: <IoMdCheckmarkCircleOutline className="logo-img" />,
      parah: t("BrishAccred"),
    },
  ];

  return (
    <div className="learn-cont">
      <div className="learn-headerCont">
        <h1 className="head">{t("WhyPreston_h2")}</h1>
        <h1 className="head red">{t("WhyPreston__red_h2")}</h1>
        <div className="line"></div>
      </div>
      {/* //* For the logo and details  */}
      <div className="logo-items">
        {LogoData.map((logo, index) => {
          return (
            <div className="logo-item">
              {logo.logo}
              <p className="">{logo.parah}</p>
            </div>
          );
        })}
      </div>
      <div className="line"></div>
    </div>
  );
};

export default LearnEnglish;
