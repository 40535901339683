import React from "react";
import Time from "../../../assets/img/time.svg";
import TeachingRoom from "../../../assets/img/class.svg";
// import Unlimted from "../../../assets/img/access.svg";
import "./style/offer.css";
import { useTranslation } from "react-i18next";

const Offer = () => {
  const { t } = useTranslation();

  return (
    <div className="offer-cont">
      <div className="offer-detail">
        <div className="detail-cont">
          <img src={TeachingRoom} alt="" className="teach" />
          <h3>{t("greatTeach_h2")}</h3>
          <p>{t("greatTeach_p")}</p>
        </div>
        <div className="detail-cont">
          <img src={Time} alt="" />
          <h3>{t("twoFour_h2")}</h3>
          <p>{t("twoFour_p")}</p>
        </div>
      </div>
    </div>
  );
};

export default Offer;
