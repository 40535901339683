import React, { useEffect } from "react";
import "./general.css";
import { NavLink } from "react-router-dom";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import ProgressionChart from "../../../assets/img/Student Progression chart.png";
import CourseDescription from "../../../assets/files/Course Descriptor for Students A1 to C1.pdf";
import { useTranslation } from "react-i18next";

const GeneralEnglish = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "General - English - Preston Academy of English";
  }, []);
  return (
    <>
      <Header />
      <div className="general-cont">
        <h1 className="general-header">{t("generalEnglish_h1")}</h1>
        <div className="general-detailCont">
          <p className="general-para">{t("generalEnglish_p")}</p>
          <div className="general-list">
            <p className="generalList-header">
              {t("generalEnglish_smallParah")}
            </p>
            <ul className="general-listBox">
              <li className="general-listText">{t("generalEnglish_1")}</li>

              <li className="general-listText"> {t("generalEnglish_2")}</li>
              <li className="general-listText"> {t("generalEnglish_3")}</li>
              <li className="general-listText"> {t("generalEnglish_4")}</li>
              <li className="general-listText">{t("generalEnglish_5")}</li>
              <li className="general-listText">{t("generalEnglish_6")}</li>
              <li className="general-listText">{t("generalEnglish_7")}</li>
              <li className="general-listText">{t("generalEnglish_8")}</li>
            </ul>
          </div>

          <div className="general-list">
            <p className="generalList-header">{t("generalEnglishLevels_p")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("generaLevel_1")}</li>
              <li className="general-listText">{t("generaLevel_2")}</li>
              <li className="general-listText">{t("generaLevel_3")}</li>
              <li className="general-listText">{t("generaLevel_4")}</li>
              <li className="general-listText">{t("generaLevel_5")}</li>
              <li className="general-listText">{t("generaLevel_6")}</li>
            </ul>
          </div>
          {/* //! For the table */}
          <div className="general-tableCont">
            <h1>Course Summary</h1>
            <table className="general-table">
              <thead>
                <th>
                  <td>Hours</td>
                </th>
                <th>
                  <td>Days</td>
                </th>
                <th>
                  <td>Time (Am)</td>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>6 </td>
                  <td>Any 2 days</td>
                  <td>9.00 - 12.15</td>
                </tr>
                <tr>
                  <td>9 </td>
                  <td>Any 3 days</td>
                  <td>9.00 - 12.15</td>
                </tr>
                <tr>
                  <td>12 </td>
                  <td>Any 4 days</td>
                  <td>9.00 - 12.15</td>
                </tr>
                <tr>
                  <td>15 </td>
                  <td>Any 5 days</td>
                  <td>9.00 - 12.15</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="general-list">
            <p>
              {t("generalcorsLeng_p")} <br />
              <br />
              {t("generalmon_p")}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gov.uk/bank-holidays"
              >
                {t("generalmon_red_p")}
              </a>
            </p>
            <p>
              <span className="blue-head">{t("generalnote_p")}</span>
              <br /> <br />
              {t("generalage_p")} <br /> <br /> {t("generalclass_p")}
            </p>
          </div>

          <div className="general-btnCont">
            <NavLink to="/apply-now" className="all-courseBtn">
              Book Now
            </NavLink>
            <a className="all-courseBtn" download href={ProgressionChart}>
              Download the progression chart
            </a>
            <a className="all-courseBtn" download href={CourseDescription}>
              Download course descriptor
            </a>
          </div>

          <div className="generalOther-courseCont">
            <h4 className="generalOtherCourse-header">
              {t("generaladdmorecourse")}
            </h4>
            <div className="generalOther-boxCont">
              <NavLink to="/english-ielts" className="generalOther-courseBox">
                IELTS Exam Preparation
              </NavLink>

              <NavLink to="/speaking-skills" className="generalOther-courseBox">
                Speaking Skills
              </NavLink>
              <NavLink to="/onetoone" className="generalOther-courseBox">
                1 To 1 Courses
              </NavLink>
              <NavLink to="/ukvi-test" className="generalOther-courseBox">
                UKVI SELT A1 / A2 / B1 Test Preparation
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GeneralEnglish;
