import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";

const OneofOne = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "1 of 1 Courses - Preston Academy of English";
  }, []);
  return (
    <>
      <Header />
      <div className="general-cont">
        <h1 className="general-header">1 to 1 Courses</h1>
        <div className="general-detailCont">
          <p className="general-para">
            {t("onetoone_pbreak1")}
            <br /> <br /> {t("onetoone_pbreak2")}
          </p>
          <div className="general-list">
            <p className="generalList-header">{t("onetoone_smallp")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("onetoone_1")}</li>
              <li className="general-listText">{t("onetoone_2")}</li>
              <li className="general-listText">{t("oentoone_3")}</li>
              <li className="general-listText">{t("onetoone_4")}</li>
              <li className="general-listText">{t("oentoone_5")}</li>
              <li className="general-listText">{t("onetoone_6")}</li>
              <li className="general-listText">{t("onetoone_7")}</li>
            </ul>
          </div>
          <div className="general-list">
            <p className="generalList-header">{t("onetoonelevel_p")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("oentooneLevel_1")}</li>
              <li className="general-listText">{t("oentooneLevel_2")}</li>
              <li className="general-listText">{t("oentooneLevel_3")}</li>
              <li className="general-listText">{t("oentooneLevel_4")}</li>
              <li className="general-listText">{t("oentooneLevel_5")}</li>
              <li className="general-listText">{t("oentooneLevel_6")}</li>
            </ul>
          </div>
          {/* //! For the table */}
          <div className="general-tableCont">
            <h1>Course Summary</h1>
            <table className="general-table">
              <tbody>
                <tr>
                  <td>Price Per Hour:</td>
                  <td>£50</td>
                </tr>
                <tr>
                  <td>Enrolment Fee:</td>
                  <td>£50</td>
                </tr>
                <tr>
                  <td>Course Book:</td>
                  <td>£30</td>
                </tr>
                <tr>
                  <td className="notice-red">Please Note:</td>
                  <td className="notice-red">
                    24 Hours notice is required for cancellation or the lesson
                    will be charged.
                  </td>
                </tr>
                <tr>
                  <td>Hours per week:</td>
                  <td>Minimum 2 hours booking</td>
                </tr>
                <tr>
                  <td>Hours a Day:</td>
                  <td>Flexible</td>
                </tr>
                <tr>
                  <td>Timetable:</td>
                  <td>
                    It is your choice, any day from Monday to Thursday from
                    14.00pm to 16.00pm
                  </td>
                </tr>
                <tr>
                  <td>Start date:</td>
                  <td>
                    Any Monday(When a Monday is a bank holiday, Tuesday is the
                    start day)
                  </td>
                </tr>
                <tr>
                  <td>Age requirement:</td>
                  <td>18+</td>
                </tr>
                <tr>
                  <td>Minimum Booking:</td>
                  <td>One session</td>
                </tr>
              </tbody>
            </table>
          </div>

          <NavLink to="/apply-now" className="all-courseBtn">
            Book Now
          </NavLink>

          <div className="generalOther-courseCont">
            <h4 className="generalOtherCourse-header">
              {t("onetooneaddmorecourse")}
            </h4>
            <div className="generalOther-boxCont">
              <NavLink to="/general-english" className="generalOther-courseBox">
                General English
              </NavLink>
              <NavLink to="/english-ielts" className="generalOther-courseBox">
                IELTS Exam Preparation
              </NavLink>
              <NavLink to="/speaking-skills" className="generalOther-courseBox">
                Speaking skills
              </NavLink>

              <NavLink to="/ukvi-test" className="generalOther-courseBox">
                UKVI SELT A1 / A2 / B1 Test Preparation
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OneofOne;
