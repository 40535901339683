import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";

const EnglishPrepCours = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Speaking Skills - Preston Academy of English";
  }, []);
  return (
    <>
      <Header />
      <div className="general-cont">
        <h1 className="general-header"> Speaking Skills</h1>
        <div className="general-detailCont">
          <p className="general-para">
            {t("speak_pbreak1")} <br />
            <br />
            {t("speak_pbreak2")}
            <br /> <br />
            {t("speak_pbreak3")}
          </p>

          <div className="general-list">
            <p className="generalList-header">{t("speakuseful_smallp")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("speak_listp1")}</li>
              <li className="general-listText">{t("speak_listp2")}</li>
              <li className="general-listText">{t("speak_listp3")}</li>
            </ul>
          </div>

          <div className="general-list">
            <p className="generalList-header">{t("speak_smallp")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("speak_1")}</li>
              <li className="general-listText">{t("speak_2")} </li>
              <li className="general-listText">{t("speak_3")}</li>
              <li className="general-listText">{t("speak_4")}</li>
              <li className="general-listText">{t("speak_5")}</li>
              <li className="general-listText">{t("speak_6")}</li>
              <li className="general-listText">{t("speak_7")}</li>
              <li className="general-listText">{t("speak_8")}</li>
            </ul>
          </div>
          <div className="general-list">
            <p className="generalList-header">{t("speaklevel_p")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("speakLevel_1")}</li>
              <li className="general-listText">{t("speakLevel_2")}</li>
              <li className="general-listText">{t("speakLevel_3")}</li>
            </ul>
          </div>
          {/* //! For the table */}
          <div className="general-tableCont">
            <h1>Course information:</h1>
            <table className="general-table">
              <thead>
                <th>
                  <td>Hours a week</td>
                </th>
                <th>
                  <td>Days</td>
                </th>
                <th>
                  <td>Time</td>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>6</td>
                  <td>Monday, Tuesday, Wednesday and Thursday</td>
                  <td>12.45 – 14.15</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="general-list">
            <p>
              {t("speakcorsLeng_p")} <br />
              <br />
              {t("speakmon_p")}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gov.uk/bank-holidays"
              >
                {t("speakmon_red_p")}
              </a>
            </p>
            <p>
              <span className="blue-head">{t("speaknote_p")}</span>
              <br /> <br />
              {t("speakage_p")}
              <br /> <br /> {t("speakclass_p")}
            </p>
          </div>

          <NavLink to="/apply-now" className="all-courseBtn">
            Book Now
          </NavLink>

          <div className="generalOther-courseCont">
            <h4 className="generalOtherCourse-header">
              {t("speakaddmorecourse")}
            </h4>
            <div className="generalOther-boxCont">
              <NavLink to="/general-english" className="generalOther-courseBox">
                General English
              </NavLink>
              <NavLink to="/english-ielts" className="generalOther-courseBox">
                IELTS Exam Preparation
              </NavLink>

              <NavLink to="/onetoone" className="generalOther-courseBox">
                1 to 1 Courses
              </NavLink>
              <NavLink to="/ukvi-test" className="generalOther-courseBox">
                UKVI SELT A1 / A2 / B1 Test Preparation
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EnglishPrepCours;
