import React, { useEffect } from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EnglishIELTS = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title =
      "General English + IELTS Exam Preparation - Preston Academy of English";
  }, []);
  return (
    <>
      <Header />
      <div className="general-cont">
        <h1 className="general-header"> IELTS Exam Preparation</h1>
        {/* //! For the table */}

        <div className="general-detailCont">
          <p className="general-para">{t("ielt_p")}</p>
          <div className="general-list">
            <p className="generalList-header">{t("ielt_smallp")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("ielt_1")}</li>
              <li className="general-listText">{t("ielt_2")}</li>
              <li className="general-listText">{t("ielt_3")}</li>
              <li className="general-listText">{t("ielt_4")}</li>
              <li className="general-listText">{t("ielt_5")}</li>
              <li className="general-listText">{t("ielt_6")}</li>
              <li className="general-listText">{t("ielt_7")}</li>
            </ul>
          </div>

          <div className="general-list">
            <p className="generalList-header">{t("ieltlevel_p")}</p>
            <ul className="general-listBox">
              <li className="general-listText">{t("ieltLevel_1")}</li>
              <li className="general-listText"> {t("ieltLevel_2")}</li>
              <li className="general-listText">{t("ieltLevel_3")}</li>
            </ul>
          </div>
          {/* //! For the table */}
          <div className="general-tableCont">
            <h1>Course Summary</h1>
            <table className="general-table">
              <thead>
                <th>
                  <td>Hours a week</td>
                </th>
                <th>
                  <td>Days</td>
                </th>
                <th>
                  <td>Time</td>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>6</td>
                  <td>Monday, Tuesday, Wednesday and Thursday</td>
                  <td>12.45 – 14.15</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="general-list">
            <p>
              {t("ieltcorsLeng_p")} <br />
              <br />
              {t("ieltmon_p")}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gov.uk/bank-holidays"
              >
                {t("ieltmon_red_p")}
              </a>
            </p>
            <p>
              <a
                href="https://takeielts.britishcouncil.org/uk"
                target="_blank"
                rel="noreferrer"
              >
                {t("ieltpirce_red_p")}
              </a>
            </p>
            <p>
              <span className="blue-head">{t("ieltnote_p")}</span>
              <br /> <br />
              {t("ieltage_p")} <br /> <br /> {t("ieltclass_p")}
            </p>
          </div>

          <NavLink to="/apply-now" className="all-courseBtn">
            Book Now
          </NavLink>

          <div className="generalOther-courseCont">
            <h4 className="generalOtherCourse-header">
              {t("ieltaddmorecourse")}
            </h4>
            <div className="generalOther-boxCont">
              <NavLink to="/general-english" className="generalOther-courseBox">
                General English
              </NavLink>

              <NavLink to="/speaking-skills" className="generalOther-courseBox">
                Speaking Skills
              </NavLink>
              <NavLink to="/onetoone" className="generalOther-courseBox">
                1 to 1 Courses
              </NavLink>
              <NavLink to="/ukvi-test" className="generalOther-courseBox">
                UKVI SELT A1 / A2 / B1 Test Preparation
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EnglishIELTS;
