import React, { useEffect } from "react";
import "./course.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Course = () => {
  const { t } = useTranslation();
  // {t('courses_li1')}

  useEffect(() => {
    document.title = "Courses - Preston Academy of English";
  }, []);
  return (
    <>
      <Header />
      <div className="course-cont">
        <h1 className="course-header">{t("courses_h1")}</h1>
        <div className="course-detailCont">
          <p className="course-para">{t("courses_p")}</p>
          <div className="course-listItemBox">
            <ul className="itemBox">
              <li className="item">{t("courses_li1")}</li>
              <li className="item">{t("courses_li2")}</li>
              <li className="item"> {t("courses_li3")}</li>
              <li className="item">{t("courses_li4")}</li>
              <li className="item"> {t("courses_li5")}</li>
              <li className="item">{t("courses_li6")}</li>
              <li className="item">{t("courses_li7")}</li>
              <li className="item">{t("courses_li8")}</li>
              <li className="item">{t("courses_li9")}</li>
            </ul>
            <p className="other-para">{t("coursemoreinfp")}</p>
          </div>
        </div>
        <div className="other-courseCont">
          <NavLink to="/general-english" className="other-courseBox">
            General English
          </NavLink>
          <NavLink to="/english-ielts" className="other-courseBox">
            IELTS Exam Preparation
          </NavLink>

          <NavLink to="/speaking-skills" className="other-courseBox">
            Speaking Skills
          </NavLink>
          <NavLink to="/onetoone" className="other-courseBox">
            1 To 1 Courses
          </NavLink>
          <NavLink to="/ukvi-test" className="other-courseBox">
            UKVI SELT A1 / A2 / B1 Test Preparation
          </NavLink>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Course;
