import React from "react";
import "./style/service.css";
import General from "../../../assets/img/general.svg";
import Cambridge from "../../../assets/img/cambr.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="service-cont">
      <h1 className="service-header">{t("Our_services_h2")}</h1>
      <div className="service-paraCont">
        {/*//* For the general english container */}
        <div className="paraCont">
          <img src={General} alt="" />
          <h3 className="">{t("generalEnglish_h2")}</h3>
          <p className="">{t("generalEnglish_para")}</p>
          <NavLink to="/courses" className="para-btn">
            {t("generalEnglish_btn")}
          </NavLink>
        </div>
        {/*//* For the Cambridge IELTS Preparation Courses*/}
        <div className="paraCont">
          <img src={Cambridge} alt="" />
          <h3 className="">{t("cambridge_h2")}</h3>
          <p className="">{t("cambridge_para")}</p>
          <NavLink to="/exam-prep" className="para-btn">
            {t("cambridge_btn")}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Services;
