import React, { useState, useEffect } from "react";
import "./pop.css";
import popImg from "../../assets/img/logo.png";

const PopUpInfo = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 60000); // 1 minute in milliseconds

      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="full-screen-popup">
        <div className="popup-content">
          <p>
            Get <span>2 weeks FREE!</span> send us a message on Whatsapp.
            Minimum booking of 8 weeks.
          </p>
          <img src={popImg} alt="popimg" />
          <div className="popBtnCont">
            <a
              href="https://wa.me/441772821039"
              target="_blank"
              rel="noreferrer"
              className="messageBtn"
            >
              Message now
            </a>
            <button className="close-button" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PopUpInfo;
